

@import '../assets/css/variables.css';

.bubble-ups {
	position: absolute;
	opacity: 1;
	margin: calc(var(--border-radius) * .5);
	
	user-select: none;

	// &:after {
	// 	content: "";
	// 	position: absolute;
	// 	left: calc(var(--border-radius) * 1.25);
	// 	bottom: 0;
	// 	width: 0;
	// 	height: 0;
	// 	border-left: 0px solid transparent;
	// 	border-right: calc(var(--border-radius) * 1.25) solid transparent;
	// 	border-top: calc(var(--border-radius) * 1.25) solid var(--black);
	// 	clear: both;
	// 	z-index: -10;
	// 	transform: translateY(90%);
	// }
}

.bubble-ups-up-enter-active, .bubble-ups-up-leave-active {
	transition: opacity .5s var(--easing),
				transform .5s var(--easing);
}

.bubble-ups-up-enter {
	transform: translateY(50px);
	opacity: 0;
}

.bubble-ups-up-leave-to {
	opacity: 0;
}

.learn-more {
	display: block;
	width: 100%;
	text-align: right;
	/* font-size: .75em; */
	/* text-decoration: underline; */
	opacity: .5;
}



